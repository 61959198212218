import React, { lazy, Suspense, useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Loader from "../components/common/loader";
import Footer from "./footer";
import Header from "./header";

const SignInPage = lazy(() => import("../pages/sign-in"));
const SignUpPage = lazy(() => import("../pages/sign-up"));
const ForgotPasswordPage = lazy(() => import("../pages/forgot-password"));
const VerifyOtpPage = lazy(() => import("../pages/verify-otp"));
const VerifyEmailOtpPage = lazy(() => import("../pages/verify-email-otp"));
const VerifyPhoneOtpPage = lazy(() => import("../pages/verify-phone-otp"));
const ResetPasswordPage = lazy(() => import("../pages/reset-password"));
const AddMobilePage = lazy(() => import("../pages/add-mobile"));

const Home = lazy(() => import("../pages/home"));
const Category = lazy(() => import("../pages/category"));
const Exam = lazy(() => import("../pages/exam"));
const Blogs = lazy(() => import("../pages/blog"));
const BlogDetailsPage = lazy(() => import("../pages/blog-details"));
const Package = lazy(() => import("../pages/package"));
const Term = lazy(() => import("../pages/term"));
const Privacy = lazy(() => import("../pages/privacy"));
const Refund = lazy(() => import("../pages/refund"));
const Contact = lazy(() => import("../pages/contact"));
const About = lazy(() => import("../pages/about"));
const ExamOverview = lazy(() => import("../pages/examOverview"));
const PackageOverview = lazy(() => import("../pages/packageOverview"));

const Profile = lazy(() => import("../pages/profile/profile"));
const ProfilePackage = lazy(() => import("../pages/profile/profile-package"));
const ProfilePackageOverview = lazy(() =>
  import("../pages/profile/profile-package-overview")
);
const ProfileExam = lazy(() => import("../pages/profile/profile-exam"));
const ProfileTransactions = lazy(() =>
  import("../pages/profile/profile-transactions")
);
const ChangePassword = lazy(() => import("../pages/profile/change-password"));
const Cart = lazy(() => import("../pages/profile/cart"));
const Wishlist = lazy(() => import("../pages/profile/wishlist"));
const MyExamView = lazy(() => import("../pages/profile/myExamView"));

const HelpDeskList = lazy(() => import("../pages/helpDesk/list"));
const HelpDeskAdd = lazy(() => import("../pages/helpDesk/add"));
const HelpDeskChat = lazy(() => import("../pages/helpDesk/chat"));
const HelpDeskListClose = lazy(() => import("../pages/helpDesk/listclosed"));

const TestInstruction = lazy(() => import("../pages/profile/testInstruction"));
const Test = lazy(() => import("../pages/test/test"));
// const TestExam = lazy(() => import("../pages/test/exam"));
// const TestAnswerView = lazy(() => import("../pages/test/answerView"));
// const TestPreviousAttempts = lazy(() =>import("../pages/test/previousAttempts"));
// const TestExam2 = lazy(() => import("../pages/test/exam2"));
// const TestExamDragDrop = lazy(() => import("../pages/test/examDragDrop"));
// const TestExamDragDropView = lazy(() => import("../pages/test/examDragDropView"));
// const TestExamHotArea = lazy(() => import("../pages/test/examHotArea"));
// const TestExamHotAreaView = lazy(() => import("../pages/test/examHotAreaView"));
// const TestExamTrueFalse = lazy(() => import("../pages/test/examTrueFalse"));
// const TestExamTrueFalseView = lazy(() =>import("../pages/test/examTrueFalseView"));
// const TestExamYesNo = lazy(() => import("../pages/test/examYesNo"));
// const TestExamYesNoView = lazy(() => import("../pages/test/examYesNoView"));
// const TestQuestion = lazy(() => import("../pages/test/question"));
// const TestReviewAnswers = lazy(() => import("../pages/test/reviewAnswers"));

const Notifiactions = lazy(() => import("../pages/notifications"));
const FaqsPage = lazy(() => import("../pages/faqs"));
const ReviewAttempt = lazy(() => import("../pages/profile/reviewAnswers"));

export default function Layout() {
  const [currentPath, setCurrentPath] = useState('');
  const {pathname} = useLocation()
  useEffect(()=>{
    if(pathname){
      let path = pathname.split("/");
      path = path[1];
      setCurrentPath(path);
    }
  }, [pathname]);

  return (
    <Suspense fallback={<Loader />}>
      <ToastContainer />
      {currentPath != "test" ? (
        <div className="main_app">
          <Header />
          <Loader />
          <div className="pageBodywrp">
            <Routes>
              <Route path="/sign-in" element={<SignInPage />} />
              <Route path="/sign-up" element={<SignUpPage />} />
              <Route path="/forgot-password" element={<ForgotPasswordPage />} />
              <Route path="/verify-otp" element={<VerifyOtpPage />} />
              <Route path="/verify-email-otp" element={<VerifyEmailOtpPage />} />
              <Route path="/verify-phone-otp" element={<VerifyPhoneOtpPage />} />
              <Route path="/reset-password" element={<ResetPasswordPage />} />
              <Route path="/add-mobile" element={<AddMobilePage />} />
              <Route path="/" element={<Home />} />
              <Route path="/category" element={<Category />} />
              <Route path="/exam" element={<Exam />} />
              <Route path="/exam?" element={<Exam />} />
              <Route path="/blog" element={<Blogs />} />
              <Route path="/blog-detail/:id" element={<BlogDetailsPage />} />
              <Route path="/package" element={<Package />} />
              <Route path="/terms-condition" element={<Term />} />
              <Route path="/privacy-policy" element={<Privacy />} />
              <Route path="/refund-policy" element={<Refund />} />
              <Route path="/contact-us" element={<Contact />} />
              <Route path="/about" element={<About />} />
              <Route path="/exam-overview" element={<ExamOverview />} />
              <Route path="/attempt/:attemptId" element={<ReviewAttempt />} />
              <Route path="/package-overview/:id" element={<PackageOverview />} /> 
              <Route path="/profile" element={<Profile />} />
              <Route path="/profile/package/:subscId" element={<ProfilePackageOverview />} />
              <Route path="/profile/package/active/:page" element={<ProfilePackage />}  />
              <Route path="/profile/package/expired/:page"  element={<ProfilePackage />}/>
              <Route path="/profile/exam/active" element={<ProfileExam />} />
              <Route path="/profile/exam/expired" element={<ProfileExam />} />
              <Route path="/profile/exam/:subscId" element={<MyExamView />} />
              <Route path="/profile/transaction" element={<ProfileTransactions />} />
              <Route path="/profile/transaction/:page" element={<ProfileTransactions />} />
              <Route path="/change-password" element={<ChangePassword />} />
              <Route path="/helpdesk" element={<HelpDeskList />} />
              <Route path="/helpdesk/add" element={<HelpDeskAdd />} />
              <Route path="/helpdesk/chat/:id" element={<HelpDeskChat />} />
              <Route  path="/helpdesk-closed"  element={<HelpDeskListClose />} />
              <Route path="/cart" element={<Cart />} />
              <Route path="/wishlist" element={<Wishlist />} />
              <Route path="/instruction/:subscId/:examId/:testId" element={<TestInstruction />} />
              <Route  path="/notifications"  element={<Notifiactions />} />
              <Route  path="/faqs"  element={<FaqsPage />} />
            </Routes>
          </div>
          <Footer />
        </div>
      ) : currentPath == "test" ? (
        <Routes>
          <Route path="/test/:attemptId/"  element={<Test />} />
          <Route path="/test/:attemptId/:sectionId"  element={<Test />} />
          <Route path="/test/:attemptId/:sectionId/:qsnId"  element={<Test />} />
        </Routes>
      ) : null}
    </Suspense>
  );
}
