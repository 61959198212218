import React from 'react'
import { Link } from 'react-router-dom';
import SubCategoryDropDown from './subCategory';

const CategoryDropDown = (props) => {
    const {data} = props;
  return (
    <>
        {data.map((post,i1) => (
            (
                <div className="subMenuWrp" key={i1}>
                    <a>
                    {/* <Link to={`exam?category=${post._id}`}> */}
                        <span className='d-flex justify-content-between'>
                            <span>{post.name}</span>

                            {post?.subCategories?.length > 0 && (
                                <span><i className='fa fa-chevron-right'></i></span>
                            )}
                        </span>
                    {/* </Link> */}
                    </a>
                    <SubCategoryDropDown data={post} categoryId={post._id}/>
                </div>
            )
        ))}
    </>
  )
}

export default CategoryDropDown