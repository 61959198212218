import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Newsletter from "../pages/newsletter";
import ROOT_URL from "../services/api-url";
import authAxios from "../services/authAxios";
import common from "../services/common";

export default function Footer() {
  const [getFooter, setGetFooter]= useState({
    address: '', 
    email: '',  
    phone: '', 
    follow_on_twitter: '', 
    follow_on_facebook:'', 
    follow_on_linkdin:''
  })

  //get footer get all
  const getFooterData = async() => {
    await authAxios({
        method: "GET",
        url: `${ROOT_URL}/pages/footer/getAll`,
    }).then((res)=>{
      setGetFooter(res.data.data.section); 
    }).catch((error) => {
        common.error(error);
    })
}


useEffect(()=>{
  getFooterData();
},[])
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-md-6">
              <a className="footer-logo1" href="#">
                <img src="/assets/images/footer-logo.png" />
              </a>             
              <ul className="footer-data">
                <li>
                  <Link to="/">                   
                    <i className="fa-solid fa-location-dot" aria-hidden="true"></i> {getFooter.address}
                  </Link>                  
                </li>
                <li>
                  <Link to="/">                 
                    <i className="fa-solid fa-envelope" aria-hidden="true"></i> {getFooter.email}
                  </Link>                  
                </li>
                <li>
                  <Link to="/">                                     
                    <i className="fa-solid fa-phone" aria-hidden="true"></i> {getFooter.phone}
                  </Link>                 
                </li>
              </ul>
            </div>
            <div className="col-xl-3 col-lg-2 col-md-3">
              <h3>Quick Links</h3>
              <ul className="useful-links">
                <li>
                  <Link to="/about">About Us</Link>
                </li>
                <li>
                  <Link to="/faqs">FAQ'S</Link>
                </li>
                <li>
                  <Link to="/blog">Blog</Link>
                </li>               
              </ul>
            </div>
            <div className="col-xl-3 col-lg-2 col-md-3">
              <h3>Legal</h3>
              <ul className="useful-links">
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/terms-condition">Terms & Conditions</Link>
                </li>                
              </ul>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-12">
            <h3>Follow on</h3>
              <ul className="social-icons">
                <li>
                  <Link to={`${getFooter.follow_on_twitter}`}> 
                    <i className="fab fa-twitter" aria-hidden="true"></i>
                  </Link>
                </li>
                <li>
                  <Link to={`${getFooter.follow_on_facebook}`}>
                    <i className="fab fa-facebook" aria-hidden="true"></i>
                  </Link>
                </li>
                <li>
                  <Link to={`${getFooter.follow_on_linkdin}`}>
                    <i className="fab fa-linkedin" aria-hidden="true"></i>
                  </Link>
                </li>
              </ul>
              <Newsletter />
            </div>
          </div>
        </div>
        <div className="version">{common.version()}</div>
        <div className="copyright">@Certometrics. All rights reserved.</div>
      </footer>
    </>
  );
}
