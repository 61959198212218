import { createContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { reactLocalStorage } from "reactjs-localstorage";
import ROOT_URL from '../services/api-url';
import authAxios from '../services/authAxios';
import common from '../services/common';

export const UserContext = createContext();

// This context provider is passed to any component requiring the context
export const UserProvider = ({ children }) => {
    const navigate = useNavigate()
    const [isLogin, setLogin] = useState(false);
    const [userInfo, setUserInfo] = useState({});
    const [userCountry, setUserCountry] = useState("");
    const [attemptData , setAttemptData]  = useState({});
    const [cartCounter , setCartCounter]  = useState({});
    const [totalQsn , setTotalQsn]  = useState(0);
    const [examPlayPause , setExamPlayPause]  = useState('ongoing');
    const [isSubmit, setIsSubmit] = useState(false);
    const [comCount, setComCount] = useState("");

    const reset = () => {
      setLogin(false);
      localStorage.removeItem("token");
      setCartCounter({});
      setAttemptData({});
      navigate("/signin");
    };
    useEffect(() => {
      let authLocal = reactLocalStorage.get("token");
      setLogin(authLocal);
    }, []);
    const getCartWishlist = () => {
      let notiData = {
        page: 1,
        perPage: 20,
      };
      authAxios({
        method: "GET",
        url: `${ROOT_URL}/cart/getTotalCount`,
      })
        .then((res) => {
          setCartCounter(res?.data || "");
        })
        .catch((error) => {
          // common.error(error);
        });
    };

    return (
      <UserContext.Provider
        value={{
          isLogin,
          setLogin,
          reset,
          userInfo,
          setUserInfo,
          setUserCountry,
          userCountry,
          attemptData,
          setAttemptData,
          totalQsn,
          setTotalQsn,
          examPlayPause,
          setExamPlayPause,
          cartCounter,
          getCartWishlist,
          isSubmit,
          setIsSubmit,
          comCount,
          setComCount,
        }}
      >
        {children}
      </UserContext.Provider>
    );
  };
